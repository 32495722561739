'use client';


import {useTranslation} from "@/i18n/client";
import Link from "next/link";
import {useLocale} from "@/hooks/locale-provider";

function NotFoundPage() {

    const { t } = useTranslation("common");
    const locale = useLocale();

      return (
          <html lang={locale}>
          <body>
              <div style={{
                  width: '100%',
                  padding: '10rem 0',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem"
              }}>
                  <h1>{t('404')}</h1>
                  <p>{t('404-desc')}</p>
                  <img src={"/images/404.svg"} width={400} height={400}/>
                  <Link href={`/${locale}/`} className={"button red"}>{t("404-home")}</Link>

              </div>
          </body>
          </html>
      );
}

export default NotFoundPage;

/*
 */